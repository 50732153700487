import PropTypes from 'prop-types'

function CustomImage({ mainSrc, srcSetPaths, alt, width, height, className, imgCDN, loading }) {
    return (
        <picture>
            {srcSetPaths.length > 0 && srcSetPaths.map((srcPath, i) => (
                <source srcSet={imgCDN ? process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT + srcPath.path : srcPath.path} type={srcPath.type} key={i} />
            ))
            }
            <img src={imgCDN ? process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT + mainSrc : mainSrc} alt={alt} width={width} height={height} className={className} loading={loading} />
        </picture>
    )
}

CustomImage.propTypes = {
    /** @mainSrc: This indicate main or fallback png , jpg, svg version of the file  */
    mainSrc: PropTypes.string.isRequired,
    alt: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
    srcSetPaths: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string, PropTypes.string)),
    imgCDN: PropTypes.bool,
    loading: PropTypes.string
}

CustomImage.defaultProps = {
    alt: '',
    height: 'auto',
    width: 'auto',
    srcSetPaths: [],
    className: '',
    imgCDN: false,
    loading: 'lazy'
}

export default CustomImage;

